WebFont.load({
  google: {
    families: [
      'Open Sans:300,300italic,400,400italic,600,600italic,700,700italic,800,800italic',
      'Roboto:100,300,regular,500,700,900',
      'Cormorant:regular,600',
      'Poppins:200,regular,500,600,700,800,900',
    ],
  },
});
